<template>
  <v-card class="confirmation-box py-8" elevation="2">
    <div>
      <img src="../assets/img/logo_jangadeiro.png" />
    </div>
    <div class="confirmation-description">
      <p>Digite o código de verificação enviado para o e-mail cadastrado</p>
    </div>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        class="mx-5"
        lazy-validation
        @submit.prevent="confirmSignup()"
      >
        <v-text-field
          v-model="code"
          :rules="[rules.required]"
          label="Código"
          outlined
          rounded
          required
        />
        <v-btn
          block
          :disabled="!valid || loading"
          color="primary"
          class="confirmation-btn"
          large
          rounded
          type="submit"
          @click="confirmSignup()"
        >
          <v-progress-circular
            v-if="loading"
            indeterminate
            size="25"
            width="3"
            color="white"
          />
          <span v-else class="white--text">Confirmar cadastro</span>
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  props: {
    username: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      valid: true,
      code: "",
      rules: {
        required: (value) => !!value || "Este campo é obrigatório.",
      },
    };
  },
  methods: {
    async confirmSignup() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        Auth.confirmSignUp(this.username, this.code)
          .then((data) => {
            if (data === "SUCCESS") {
              this.$toast.success("Cadastro confirmado com sucesso.");
              this.$router.push("/");
            } else {
              throw new Error("Status different than SUCCESS!");
            }
          })
          .catch((error) => {
            console.log("error confirming sign up", error);
            this.loading = false;
            this.$toast.error(
              "Algo de errado aconteceu. Verifique o seu código e tente novamente."
            );
          });
      }
    },
  },
};
</script>

<style scoped>
.confirmation-box {
  background-color: #fff;
  max-width: 500px;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}

.confirmation-description p {
  text-align: center;
  margin-bottom: 40px;
  color: #a3a3a3;
}

.confirmation-btn {
  font-weight: bold;
  letter-spacing: 0 !important;
}
</style>
